import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class Gut2 extends Component {

  render() {
    return (

<div class="d-flex justify-content-center mt-2">

<div class="container">

<section id="projects" class="projects-section bg-light">

<div class="row align-items-center no-gutters mb-1 mb-lg-1">
<div class="col-xl-8 col-lg-7">
<img class="img-fluid mb-1 mb-lg-0" src="../img/worldlaw-logo.png" alt="WL-logo"></img>
</div>
<div class="col-xl-4 col-lg-5">
<div class="featured-text text-center text-lg-left">
<h4>Something neat has to go here. Something neat has to go here. Something neat has to go here. </h4>
<p class="text-black-50 mb-0">Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. Something neat has to go here. </p>
</div>
</div>
</div>


<div class="row justify-content-center no-gutters mb-1 mb-lg-0">
<div class="col-lg-6">
<img class="img-fluid" src="../img/bluefin-01.png" alt="SearchIcon"></img>
</div>
<div class="col-lg-6">
<div class="d-flex h-100">
<div class="project-text w-100 my-auto text-center text-lg-left">
<h4 class="text-black">Prepare for Facts</h4>
<p class="mb-0 text-black-50">Client files are stored on a public network of decentralized nodes and the Ethereum blockchain. Web3bits agents use the Web3bits tools to interact with clients, their claim files and with necessary subcontractors. The Web3bits NFT minterallows for presentations of facts surrounding the claim process.</p>
</div>
</div>
</div>
</div>

<div class="row justify-content-center no-gutters">
<div class="col-lg-6">
<img class="img-fluid" src="../img/bluefin-02.png" alt="BadgeTieIcon"></img>
</div>
<div class="col-lg-6 order-lg-first">
<div class="d-flex h-100">
<div class="project-text w-100 my-auto text-center text-lg-right">
<h4 class="text-black">Create a users.eth user account now.</h4>
<p class="mb-0 text-black-50">It’s free to sign-up and only requires a Web3 ready browser like Opera or a plug-in like MetaMask that allows interaction of the WorldLaw protocols that your clients will use to manage their evidence files.</p>
</div>
</div>
</div>
</div>


<div class="row justify-content-center no-gutters mb-1 mb-lg-0">
<div class="col-lg-6">
<img class="img-fluid" src="../img/bluefin-03.png" alt="HandsShieldIcon"></img>
</div>
<div class="col-lg-6">
<div class="d-flex h-100">
<div class="project-text w-100 my-auto text-center text-lg-left">
<h4 class="text-black">Search the Ethereum blockchain now.</h4>
<p class="mb-0 text-black-50">It’s free to sign-up and only requires a Web3 ready browser like Opera or a plug-in like MetaMask that allows interaction of the Web3bits protocols that your clients will use to manage their evidence files.</p>
</div>
</div>
</div>
</div>

  </section>

  </div>

</div>

);
}
}

export default Gut2;
